import {
  json,
  type ActionFunctionArgs,
  type LoaderFunctionArgs } from
'@remix-run/cloudflare';
import styles from 'app/assets/styles/login.css';
import type { ActionResponse } from 'app/components/Login';
import Login from 'app/components/Login';
import { redirect, typedjson, useTypedActionData } from 'remix-typedjson';
import { useLoaderData } from '@remix-run/react';
import {
  commitSession,
  destroySession,
  getCoachSession,
  isAuthenticated } from
'app/helpers/session.server';
import { createI18nInstance } from 'app/helpers/I18n.server';
import { addDays } from 'date-fns';
import {
  Snackbar,
  createSnackbarSession,
  useSnackbar } from
'@monorepo_2022/app-utils/src/snackbar';

export const links = () => [{ rel: 'stylesheet', href: styles }];

export const action = async ({ request, context }: ActionFunctionArgs) => {
  const { t } = await createI18nInstance();
  const env = context.env;
  let res: Response | null = null;
  const body = await request.formData();

  try {
    const req = new Request('http://stub/signin', {
      method: 'POST',
      body
    });

    res = await env.AuthService.fetch(req);
  } catch (error) {
    if (error instanceof Error) {
      return typedjson({
        error
      });
    }

    return typedjson({
      error: t('login.error.invalidIdAndPassword')
    });
  }

  const session = await getCoachSession(request);

  if (res.status !== 200) {
    return typedjson({
      error: t('login.error.invalidIdAndPassword')
    });
  }

  const data = await res.json<{
    localId: string;
    token: string;
    refreshToken: string;
  }>();

  session.set('userId', data.localId);
  session.set('token', data.token);
  session.set('refreshToken', data.refreshToken);
  session.set('expirationDate', addDays(new Date(), 180));

  return redirect('/dashboard', {
    headers: {
      'Set-Cookie': await commitSession(session)
    }
  });
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  if (await isAuthenticated(request)) {
    throw redirect('/dashboard');
  }

  const session = await getCoachSession(request);
  const { getSnackbarMessage } = createSnackbarSession({ session, request });

  const message = getSnackbarMessage();

  return json(
    { message },
    {
      headers: {
        'Set-Cookie': await destroySession(session)
      }
    }
  );
};

export default function () {
  const { message } = useLoaderData<typeof loader>();
  const actionData = useTypedActionData<ActionResponse>();

  const { setSnackbarMessage } = useSnackbar();
  setSnackbarMessage(message);

  return (
    <>
      <Snackbar className="LoginSnackbar" />
      <Login action="/login" actionData={actionData} />
    </>);

}