import { Link } from '@monorepo_2022/app-utils/src/components/Link';
import { useTranslation } from 'react-i18next';
import { Form } from '@remix-run/react';
import { MinimalLayout } from 'app/components/common/MinimalLayout';
import { Input, PasswordInput, TableView } from 'app/components/common/form';

export type ActionResponse = {
  error: string | null;
};

type Props = {
  action: string;
  actionData: ActionResponse | null;
};

export default function Login({ action, actionData }: Props) {
  const { t } = useTranslation();

  return (
    <MinimalLayout>
      <Form method="post" action={action}>
        <TableView
          heading={t('login.title')}
          className="Login-tableView"
          rows={[
            {
              label: t('login.field.email.label'),
              children: (
                <Input
                  name="email"
                  id="email"
                  autoCapitalize="off"
                  autoCorrect="off"
                  autoComplete="off"
                  autoFocus
                  type="text"
                />
              ),
            },
            {
              label: t('login.field.password.label'),
              children: <PasswordInput name="password" id="password" />,
            },
          ]}
        />

        <div className="Login-errorMessage">
          {actionData?.error && <p>{actionData.error}</p>}
        </div>

        <p>
          <button type="submit" className="button Login-button">
            {t('login.submit')}
          </button>
        </p>

        <p className="Login-forgetPassword">
          <Link to="/forgot-password">{t('login.forgotPassword')}</Link>
        </p>
      </Form>
    </MinimalLayout>
  );
}
